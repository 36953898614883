import { createContext, useReducer } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import analytics from './analytics/analytics';

export const APP_ACTIONS = {
  SET_COMPANY: 'SET_COMPANY',
};

export const USER_AUTH_LIFECYCLE_ACTIONS = {
  UPDATE_USER: 'UPDATE_USER',
  CLEAR_USER: 'CLEAR_USER',
};

export const MODAL_MESSAGE_STATUS_ACTIONS = {
  MODAL_MESSAGE: 'MODAL_MESSAGE',
  MODAL_MESSAGES: 'MODAL_MESSAGES',
  CLEAR_SPECIFIC_MODAL: 'CLEAR_SPECIFIC_MODAL',
  FREEZE_MODALS: 'FREEZE_MODALS',
  UNFREEZE_MODALS: 'UNFREEZE_MODALS',
};

export const SOCKET_ACTIONS = {
  SET_SOCKET: 'SET_SOCKET',
  CLEAR_SOCKET: 'CLEAR_SOCKET',
};

export const QUESTS_ACTIONS = {
  SET_CATALOG_QUESTS: 'SET_CATALOG_QUESTS',
};

export const ALERTS = {
  SET_ALERT: 'SET_ALERT',
  DISMISS_ALERT: 'DISMISS_ALERT',
};
export const CURRENT_COMPANY_ID_KEY = 'currentCompanyId';

export const AppContext = createContext();

const initState = {
  user: null,
  catalogQuests: null,
  fetch: { pending: [] },
  modals: [],
  freezeModals: false,
  socket: null,
  alerts: [],
  currentCompanyId: localStorage.getItem(CURRENT_COMPANY_ID_KEY),
};

const reducer = (state, action, queryClient) => {
  switch (action.type) {
    case USER_AUTH_LIFECYCLE_ACTIONS.UPDATE_USER: {
      const userProps = action.payload;
      const user = { ...state.user, ...userProps };
      analytics.identify(user);
      return {
        ...state,
        user,
      };
    }
    case USER_AUTH_LIFECYCLE_ACTIONS.CLEAR_USER: {
      analytics.reset();
      return { ...state, user: null };
    }
    case APP_ACTIONS.SET_COMPANY: {
      const { companyId } = action.payload;
      localStorage.setItem(CURRENT_COMPANY_ID_KEY, companyId);
      queryClient?.invalidateQueries();
      return {
        ...state,
        currentCompanyId: companyId,
      };
    }
    case MODAL_MESSAGE_STATUS_ACTIONS.MODAL_MESSAGE: {
      const updatedModals = [...state.modals, action.payload];
      updatedModals.sort((x, y) => y.priority - x.priority);
      return { ...state, modals: updatedModals };
    }
    case MODAL_MESSAGE_STATUS_ACTIONS.MODAL_MESSAGES: {
      const updatedModals = [...state.modals, ...action.payload.notifications];
      updatedModals.sort((x, y) => y.priority - x.priority);
      return { ...state, modals: updatedModals };
    }
    case MODAL_MESSAGE_STATUS_ACTIONS.CLEAR_SPECIFIC_MODAL: {
      return {
        ...state,
        modals: state.modals.filter(modal => modal.id !== action.payload.id),
      };
    }
    case MODAL_MESSAGE_STATUS_ACTIONS.FREEZE_MODALS: {
      return {
        ...state,
        freezeModals: true,
      };
    }
    case MODAL_MESSAGE_STATUS_ACTIONS.UNFREEZE_MODALS: {
      return {
        ...state,
        freezeModals: false,
      };
    }
    case SOCKET_ACTIONS.SET_SOCKET: {
      return {
        ...state,
        socket: action.payload.socket,
      };
    }
    case SOCKET_ACTIONS.CLEAR_SOCKET: {
      return {
        ...state,
        socket: null,
      };
    }
    case QUESTS_ACTIONS.SET_CATALOG_QUESTS: {
      const quests = action.payload;
      return {
        ...state,
        catalogQuests: quests,
      };
    }
    case ALERTS.SET_ALERT: {
      const alert = action.payload;
      if (state.alerts.some(x => x.type === alert.type)) {
        return state;
      }
      const alerts = [...state.alerts, alert];
      alerts.sort((x, y) => y.priority - x.priority);
      return {
        ...state,
        alerts,
      };
    }
    case ALERTS.DISMISS_ALERT: {
      const alert = action.payload;
      return {
        ...state,
        alerts: state.alerts.filter(x => x.type !== alert.type),
      };
    }
    default:
      return state;
  }
};

export const AppProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useReducer(
    (state, action) => reducer(state, action, queryClient),
    initState
  );

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
