import React, { Suspense } from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import '@fontsource/readex-pro/700.css';
import '@fontsource/readex-pro/500.css';
import '@fontsource/readex-pro/400.css';
import '@fontsource/readex-pro/300.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { rollbarConfig } from './rollbar';
import { AppProvider } from './appContext';
import WilcoSplitFactory from './featureFlags/splitio/factory';
import QueryProvider from './queryProvider/QueryProvider';
import { lazyWithRetry } from './utils/react.utils';
import WilcoEngineHealthCheck from './WilcoEngineHealthCheck';
const WilcoApp = lazyWithRetry(() => import('./WilcoApp'));
const AdminApp = lazyWithRetry(() => import('./admin/App'));

function CurrentApp() {
  switch (window.location.origin) {
    case import.meta.env.VITE_ADMIN_APP_URL:
      return <AdminApp />;
    default:
      return <WilcoApp />;
  }
}

function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <QueryProvider>
          <AppProvider>
            <Router>
              <WilcoSplitFactory>
                <WilcoEngineHealthCheck>
                  <Suspense fallback={null}>
                    <CurrentApp />
                  </Suspense>
                </WilcoEngineHealthCheck>
              </WilcoSplitFactory>
            </Router>
          </AppProvider>
        </QueryProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
